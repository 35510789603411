import React, { useState } from 'react';
import './Categories.css';
import { Category } from '../data';

function Categories({ categories, onCategorySelect }: { categories: Category[], onCategorySelect: (v: number) => void}) {

  const [selectedCategory, setSelectedCategory] = useState<number>(0);

  const handleCategoryClick = (index: number) => {
    setSelectedCategory(index);
    onCategorySelect(index);
  };

  return (
    <div className="categories-tabs">
      {categories.map((item, index) => (
        <div className={`category-tab ${selectedCategory === item.id ? 'active': ''}`} 
             onClick={() => handleCategoryClick(item.id)}
             style={{ fontWeight: selectedCategory === item.id ? 'bold' : 'normal' }}>{item.title}</div>
      ))}
    </div>
  );
}

export default Categories;
