import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer id="footer" className="jsb-footer">
      <div className='footer'>
        <div className='footer-centralized-div'>
          <div className='footer-row-div'>
            LOGO GOES HERE!
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
