/* eslint-disable jsx-a11y/iframe-has-title */
import React, {ReactNode} from 'react';
import './Modal.css';

function Modal({ isOpen, onClose, children }: {isOpen: boolean, onClose: () => void, children: ReactNode}) {

  if (!isOpen) return null;

  return (
    <div 
      className="modal-overlay"
      onClick={onClose}
    >
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button onClick={onClose} className="close-button" aria-label='Close'>
          &times;
        </button>
        {children}
      </div>
    </div>
  )
}

export default Modal;
