import React from 'react';
import './Header.css';
import logo from '../../LOGO JSB-04.png';

function Header() {
  return (
    <header className="jsb-header">
      <div className='header'>
        <div className='header-centralized-div'>
          <div className='header-row-div'>
            <div className='header-left-col-div'>
              <img src={logo} alt='JSB Logo' />
            </div>
            <div className='header-right-col-div header-with-nav-div'>
              <div className='header-row-div menu-div'>
                <menu className='horizontal-menu'>
                  <li><a href="#services">Services</a></li>
                  <li><a href="#footer">Contacts</a></li>
                </menu>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
