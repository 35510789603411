import React, { useState } from 'react';
import './ServiceView.css';
import Categories from './categories/Categories';
import ServiceDetails from './service-details/ServiceDetails';
import { Category, Service } from './data';
import catInitImg from '../../resources/Service Initial.png';
import catImg1 from '../../resources/pexels-joshuaworoniecki-29273395.jpg';
import catImg2 from '../../resources/pexels-laup-27303623.jpg';
import catImg3 from '../../resources/pexels-pabloramon-20189756.jpg';

function ServiceView({openModal}:{openModal: (path: string) => void}) {

  const [selectedCategory, setSelectedCategory] = useState(0);
  const [serviceSelected, setServiceSelected] = useState<Service>()
  const [categoryImgPath, setcategoryImgPath] = useState(catInitImg);

  const file = require('./service-data.json');
  const json = JSON.stringify(file);
  const imgPathLength = catImg1.split('/').length;
  const categories: Category[] = JSON.parse(json);

  const handleCategoryClick = (selected: number) => {
    setSelectedCategory(selected);
    setServiceSelected(undefined);

    let imgName = categories.find((item: Category) => item.id === selected)?.imgName;
    if (imgName !== undefined && imgName.trim() !== "") {

      console.log(catImg1.split('/'));
      console.log(catImg2.split('/'));
      console.log(catImg3.split('/'));

      console.log(imgName);
      console.log(imgName.substring(0, imgName.length-4));
      console.log(catImg1.split('/')[imgPathLength-1]);
      console.log();

      if (catImg1.split('/')[imgPathLength-1].indexOf(imgName.substring(0, imgName.length-4)) >= 0) {
        setcategoryImgPath(catImg1);
        return;
      }

      if (catImg2.split('/')[imgPathLength-1].indexOf(imgName.substring(0, imgName.length-4)) >= 0) {
        setcategoryImgPath(catImg2);
        return;
      }

      if (catImg3.split('/')[imgPathLength-1].indexOf(imgName.substring(0, imgName.length-4)) >= 0) {
        setcategoryImgPath(catImg3);
        return;
      }
    }
  };

  return (
    <div id="services" className="services-section">
      <Categories categories={categories} onCategorySelect = {handleCategoryClick}  />

      <div className="content-area">
        <div className="services-list">
          <div id="category-1" className="category-content">
              {categories.find((item: Category) => item.id === selectedCategory)?.services.map((service: Service) => (
                <div className="service-item" onClick={ () => setServiceSelected(service)}>{service.name}</div>
              ))}
          </div>
        </div>


        <div className="service-preview">
          {serviceSelected ? (<ServiceDetails openModal={openModal} options={serviceSelected.options} />) : (<img src={categoryImgPath} alt="category-image" />)}
        </div>
      </div>
    </div>
  );
}

export default ServiceView;
